import React from "react";

const OldContent = ({ content }) => {
  return (
    <section className="journal_content__text journal_content__author container py-4 py-lg-0">
      <div className="news-inner row justify-content-center contained-xl">
        <div className="col-12 px-4 px-lg-2 col-lg-10 col-xl-10">
          {content && (
            <article
              className="oldcontent"
              dangerouslySetInnerHTML={{
                __html: content
                  .replace(/<\/noscript><\/div>/g, "</noscript></div></div>")
                  .replace(/&lt;/g, "<")
                  .replace(/&gt;/g, ">")
                  .replace(/&#8221;/g, '"')
                  .replace(/&#8220;/g, '"')
                  .replace(" </ iframe", "</iframe"),
              }}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default OldContent;
