import React from "react";
import { Link } from "gatsby";
import Image from "../Hacks/Image";

const BlogHeader = ({
  title,
  excerpt,
  category,
  date,
  author,
  bg_colour,
  bg_image,
  featured_media,
  textColour,
  readtime,
  authorName,
  authorExcerpt,
  authorAvatar,
  authorLinkedin,
  authorTitle,
}) => {
  return (
    <header
      id="hero"
      className={`
      ${
        (bg_colour ? bg_colour : "bg-grad-main") +
        " hero hero_single pb-5 " +
        (bg_image ? "hero_single-withimage" : "")
      }
    `}
    >
      {bg_image && (
        <div className="hero_single_backgorund">
          <Image
            image={featured_media}
            className="position-absolute w-100 h-100"
          />
        </div>
      )}

      <div className="position-relative container pt-4 pt-lg-5">
        <div className="py-md-5 row justify-content-center">
          <div className="hero-intro col-lg-11">
            <div className="bg-white negative-margin text-center py-4 px-3 p-md-5 pb-lg-1">
              {category && (
                <div className={`h5 journal_category ${textColour}`}>
                  <Link
                    cover={`true`}
                    direction="right"
                    bg="#1F1A3C"
                    to={`/blog/category/${category.slug}/`}
                  >
                    {category.name}
                  </Link>
                </div>
              )}
              <div className={`journal_date `}>{date}</div>
              <h1
                className={`journal_title ${textColour} `}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              {readtime && (
                <div className={`journal_readtime mb-4 mb-lg-5`}>
                  {readtime} min read
                </div>
              )}

              <p
                className="journal_lead lead mb-0 mb-lg-4 text-center"
                dangerouslySetInnerHTML={{ __html: excerpt }}
              />
              {authorName && (
                <div className="journal_authortop mt-4 mb-4">
                  <Image
                    image={authorAvatar}
                    className="rounded-circle"
                    alt={authorTitle}
                  />

                  <p className="mb-0">
                    Written by <strong>{authorName}</strong> - {authorTitle}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default BlogHeader;
