import React from "react";
import PropTypes from "prop-types";
// import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";

import BlogHeader from "../components/Blog/BlogHeader";
import OldContent from "../components/Blog/OldContent";

import useSiteMetadata from "../components/SiteMetadata";
import Seo from "../components/General/Seo";

const Vacancy = ({ data }) => {
  const { markdownRemark: post } = data;
  const { meta, social } = useSiteMetadata();

  return (
    <Layout>
      <Seo
        title={
          post.frontmatter.title +
          meta.sitePageTitleSep +
          "Vacancies" +
          meta.sitePageTitleSep +
          meta.siteTitle
        }
        slug={meta.siteUrl + post.fields.slug}
        description={
          post.frontmatter.metaDescription
            ? post.frontmatter.metaDescription
            : post.frontmatter.excerpt
            ? post.frontmatter.excerpt
            : meta.blogDescription
        }
        image={
          meta.siteUrl +
          (post.frontmatter.metaImage
            ? post.frontmatter.metaImage
            : post.frontmatter.image
            ? post.frontmatter.image.childImageSharp.fixed.src
            : meta.siteImage)
        }
        twitterTitle={post.frontmatter.title}
        facebookTitle={post.frontmatter.title}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />
      <BlogHeader
        title={post.frontmatter.title}
        excerpt={post.frontmatter.excerpt}
        category={``}
        date={``}
        author={post.fields.author}
        bg_colour={`bg-light`}
        bg_image={``}
        featured_media={``}
        textColour={`text-secondary`}
      />
      <main id="main" className={`journal_content journal-`} data-aos="fade-up">
        <OldContent content={post.html} />

        <div className="container-fluid my-5">
          <div className="row justify-content-center">
            <div className="col-11 col-lg-10">
              <div className="row ">
                <div className="order-lg-2 col-lg-6 col-xl-8 p-0 align-self-center">
                  <div className="">
                    <img
                      src="/img/db-culture-5.jpg"
                      className="rounded-lg-lg shadow img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="order-lg-1 col-lg-6 col-xl-4 shadow z-index-100 h-100 bg-secondary align-self-center">
                  <div className="py-4 px-3 p-md-5">
                    <h2 className="h2 text-left">How to Apply</h2>
                    <p>
                      Our process is simple, we review and contact you within 2
                      working days about the next steps. We offer an initial
                      informal chat and if that goes well, we up with an
                      interview.
                    </p>
                    <p>
                      If all of this sounds intersting to you, then please send
                      your CV, covering letter and any examples of your work to:
                    </p>
                    <p className="mt-5 mb-4">
                      <a
                        href="mailto:info@databowl.com"
                        className="btn btn-sm btn-outline-white"
                      >
                        info@databowl.com
                      </a>
                    </p>

                    {/* <p className="small mt-4">
                      You can also apply on {" "}
                      <a href="/indeed/" className="text-white">
                        Indeed
                      </a>
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-5">
                <div className="order-lg-1 col-lg-6 col-xl-4 text-center">
                  <div className="py-4 px-3 p-md-5">
                    <Link
                      to="/current-vacancies/"
                      className="btn btn-secondary btn-lg"
                    >
                      Back to Vacancies
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

Vacancy.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Vacancy;

export const pageQuery = graphql`
  query VacancyByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        # tags
      }
      fields {
        slug
      }
    }
  }
`;
